import React from 'react'
import Headphones from '../Headphones'
import './index.scss'

const OverlayLetter = () => {
    return (
        <div className="letterContainer">
            <div id="letter" className="letterOverlay">
                <h3 style={{textAlign:'center'}}>
                    Gutschein:
                    </h3>
                    <p style={{textAlign: 'center'}}>
                        Für neue Bluetooth-Kopfhörer
                    </p>
                <div className="letterHeartContainer">
                    <div className="letterHeart">

                    </div>
                </div>

                    <Headphones />
            </div>
        </div>
    )
}

export default OverlayLetter