import React from 'react'
import './index.scss'

const Headphones = () => {
    return (
        <div className="container">
            <div className="headphones">
                <div className="head"></div>
                <div className="ear"></div>
            </div>
        </div>
    )
}

export default Headphones