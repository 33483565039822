import React from 'react';
import './App.scss';
import OverlayLetter from './OverlayLetter';

const App = () => {

  const open = () => {
    document.getElementById("envelope")?.classList.remove("close")
    document.getElementById("envelope")?.classList.add("open")
    setTimeout(() => {
      document.getElementById("letter")?.classList.add("displayLetter")
    }, 4000)
  }

  return (
    <>
      <div className="envlope-wrapper">
        <div onClick={open} id="envelope" className="close">
          <div className="front flap"></div>
          <div className="front pocket"></div>
          <div className="letter">
            <div className="words line1"></div>
            <div className="words line2"></div>
            <div className="words line3"></div>
            <div className="words line4"></div>
          </div>
          <div className="hearts">
            <div className="heart a1"></div>
            <div className="heart a2"></div>
            <div className="heart a3"></div>
          </div>
        </div>
      </div>
      <OverlayLetter />
      </>
  )
}

export default App;
